import React from "react";
import { Stack, Text } from "@origin-digital/ods-core";
import { IconCancel, IconChevronLeft } from "@origin-digital/ods-icons";
import styled from "styled-components";
import { NavTitle } from "./NavTitle";
import { ActionBarProps } from "./ActionBar";

export const IconButton = styled.button`
  appearance: none;
  align-items: center;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  line-height: 24px;
  padding: 12px;
  user-select: none;
  vertical-align: middle;
  width: auto;
  outline: none;
`;

const CloseText = styled(Text)`
  padding-left: 4px;
`;

export interface ActionBarMobileProps extends ActionBarProps {
  title?: string;
  isDesktop?: boolean;
}

export function getMobileNavButton(
  mobileNavButton: ActionBarProps["mobileNavButton"],
  onClickMobileNavButton: ActionBarProps["onClickMobileNavButton"],
  isDesktop: boolean = false,
  desktopVariant?: ActionBarProps["desktopVariant"]
) {
  if (mobileNavButton === "none") {
    return null;
  } else if (mobileNavButton === "close") {
    return (
      <IconButton
        data-id={`button-close${isDesktop ? "-desktop" : ""}`}
        onClick={onClickMobileNavButton}
        aria-label="Close Flow"
      >
        <IconCancel tone="critical" />
        {desktopVariant === "slim" ? (
          <CloseText tone="critical" weight="medium">
            Close
          </CloseText>
        ) : null}
      </IconButton>
    );
  } else if (mobileNavButton === "back") {
    return (
      <IconButton
        data-id={`button-back${isDesktop ? "-desktop" : ""}`}
        onClick={onClickMobileNavButton}
        aria-label="Back"
      >
        <IconChevronLeft tone="critical" />
        {desktopVariant === "slim" ? (
          <Text tone="critical" weight="medium">
            Back
          </Text>
        ) : null}
      </IconButton>
    );
  }
  return null;
}

const Container = styled.div`
  width: 100%;
`;

export const ActionBarMobile = ({
  mobileNavButton,
  headingComponent,
  onClickMobileNavButton,
  title,
  showPropertySelector,
  propertySelector,
}: ActionBarMobileProps) => {
  return (
    <Container>
      <Stack
        space="none"
        data-id="mobile-container"
        className="accelerated-element"
      >
        <NavTitle
          mobileNavButton={mobileNavButton}
          headingComponent={headingComponent}
          onClickMobileNavButton={onClickMobileNavButton}
          title={title}
          showPropertySelector={showPropertySelector}
          propertySelector={propertySelector}
        />
      </Stack>
    </Container>
  );
};
