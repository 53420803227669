import React from "react";
import styled from "styled-components";

import { Box, Column, Columns, Hidden } from "@origin-digital/ods-core";
import { NavigationProvider } from "@origin-digital/navigation-client-sdk";

import SmallerLogo from "./SmallerLogo";
import { IKrakenAuthNavProps } from "./interfaces";
import { MobileNav } from "./Mobile/MobileNav";
import { CollapsibleNavLink } from "./CollapsibleNavLink";
import { authNavRoutePrefix } from "./helpers/consts";
import { DesktopNav } from "./Desktop/DesktopNav";
import { helpLink } from "./helpConfig";

const StyledBox = styled(Box)(({ theme: { palette } }) => ({
  borderBottom: `1px solid ${palette.grey["200"]}`,
}));

export const KrakenAuthNav: React.FC<IKrakenAuthNavProps> = ({
  desktopVariant,
}) => {
  const showFullHeader = desktopVariant !== "slim";
  const menuDataId = showFullHeader ? "kraken-auth-nav" : "no-nav-header";

  return (
    <StyledBox
      backgroundColor="white"
      data-id={menuDataId}
      component="nav"
      aria-label="Main"
    >
      <Columns space={["medium", "large"]}>
        <Column width="content">
          <SmallerLogo
            id="origin-logo"
            href={authNavRoutePrefix}
            desktopVariant={desktopVariant}
          />
        </Column>
        {showFullHeader ? (
          <>
            <Column width="flex" alignX={["left", "center"]}>
              <NavigationProvider>
                <>
                  <Hidden below="lg">
                    <DesktopNav />
                  </Hidden>
                  <Hidden above="lg">
                    <MobileNav />
                  </Hidden>
                </>
              </NavigationProvider>
            </Column>
            <Column width="content">
              <CollapsibleNavLink key={helpLink.name} linkConfig={helpLink} />
            </Column>
          </>
        ) : null}
      </Columns>
    </StyledBox>
  );
};
