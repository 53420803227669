/* eslint-disable no-negated-condition */
/* eslint-disable react/default-props-match-prop-types */
import {
  Breadcrumb,
  BreadcrumbProps,
  Breadcrumbs,
  Heading,
  Hidden,
  Section,
  Stack,
} from "@origin-digital/ods-core";
import React, { ReactElement } from "react";

import {
  ComponentBaseProps,
  HeadingComponentVariants,
} from "@origin-digital/ods-types";
import styled from "styled-components";
import { ActionBarMobile } from "./ActionBarMobile";
import { Box } from "./Box";
import { NavTitle } from "./NavTitle";

export interface Premise {
  premiseName: string;
  premiseId: string;
}

export type MobileNavButtonIconType = "back" | "close";
export type MobileNavButtonType = "none" | MobileNavButtonIconType;
export type MobilePropertySelectorType =
  | "none"
  | "compact"
  | "full"
  | "readOnly";

export interface Breadcrumbs
  extends Pick<BreadcrumbProps, "active" | "href" | "onClick"> {
  label: string;
}

export interface ActionBarProps extends ComponentBaseProps {
  title?: string;
  headingComponent?: HeadingComponentVariants;
  breadcrumbs?: Breadcrumbs[];
  mobileNavButton?: MobileNavButtonType;
  onClickMobileNavButton?: (
    event?: React.MouseEvent<HTMLButtonElement>
  ) => void | boolean;
  desktopVariant?: "default" | "slim";
  showPropertySelector?: boolean;
  propertySelector?: ReactElement;
}

const slimHeight = 44;
const fullHeight = 128;

const ViewContainer = styled(Box)<{
  desktopVariant: ActionBarProps["desktopVariant"];
}>`
  /* stylelint-disable-next-line media-query-no-invalid */
  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    justify-content: space-between;
    height: ${(p) => (p.desktopVariant === "slim" ? slimHeight : fullHeight)}px;
  }
`;

const StyledHidden = styled(Hidden)`
  width: 100%;
`;

const OuterBox = styled(Box)`
  overflow: hidden;
  position: relative;
  z-index: 1000;
`;

export const ActionBar: React.FC<ActionBarProps> = ({
  title,
  headingComponent = "h1",
  breadcrumbs,
  mobileNavButton = "none",
  onClickMobileNavButton,
  "data-id": dataId = "action-bar",
  desktopVariant = "default",
  showPropertySelector = false,
  propertySelector,
}) => (
  <OuterBox>
    <Box
      data-id={dataId}
      backgroundColor="white"
      display="flex"
      flexDirection="column"
      style={{ flex: 1 }}
    >
      <ViewContainer
        desktopVariant={desktopVariant}
        display="flex"
        backgroundColor="white"
        width="full"
      >
        <StyledHidden below="lg">
          <Box width="full" display="flex" height="full" alignItems="center">
            {desktopVariant !== "slim" ? (
              <Section paddingY="none" data-id="desktop-container">
                <Box display="flex" justifyContent="spaceBetween">
                  <Stack space="medium">
                    <Box
                      width="full"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {title ? (
                        <Heading
                          variant="h2"
                          data-id="actionbar-heading-desktop"
                          component={headingComponent}
                        >
                          {title}
                        </Heading>
                      ) : null}
                    </Box>
                    {breadcrumbs?.length ? (
                      <Breadcrumbs>
                        {breadcrumbs.map(({ label, ...breadcrumbProps }) => (
                          <Breadcrumb key={label} {...breadcrumbProps}>
                            {label}
                          </Breadcrumb>
                        ))}
                      </Breadcrumbs>
                    ) : null}
                  </Stack>
                  {showPropertySelector && propertySelector}
                </Box>
              </Section>
            ) : (
              <Stack>
                <NavTitle
                  mobileNavButton={mobileNavButton}
                  headingComponent={headingComponent}
                  onClickMobileNavButton={onClickMobileNavButton}
                  title={title}
                  desktopVariant={desktopVariant}
                  isDesktop={true}
                  showPropertySelector={showPropertySelector}
                  propertySelector={propertySelector}
                />
              </Stack>
            )}
          </Box>
        </StyledHidden>
        <StyledHidden above="lg">
          <ActionBarMobile
            title={title}
            headingComponent={headingComponent}
            mobileNavButton={mobileNavButton}
            onClickMobileNavButton={onClickMobileNavButton}
            showPropertySelector={showPropertySelector}
            propertySelector={propertySelector}
          />
        </StyledHidden>
      </ViewContainer>
    </Box>
  </OuterBox>
);

ActionBar.displayName = "ActionBar";
