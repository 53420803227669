import React from "react";
import { Box, Button, Stack, Text } from "@origin-digital/ods-core";
import { navTo } from "@origin-digital/event-dispatcher";
import { IconChevronRight, IconNewWindow } from "@origin-digital/ods-icons";
import { salesCommonParameters } from "@origin-digital/mcapp-registry";

const LinkButton = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children: React.ReactNode;
}) => (
  <Button
    variant="text"
    color="secondaryB"
    noTextPadding
    fullWidth
    size="small"
    component="label"
    onClick={onClick}
  >
    <Box display="flex" width="full" flexDirection="row" alignItems="center">
      {children}
    </Box>
  </Button>
);

export const PropertySheetBottomLinks = ({
  closeSheet,
}: {
  closeSheet: () => void;
}) => {
  return (
    <Stack dividers={true} space="small">
      {/*Empty box used to create dividers and spacing for links in stack */}
      <Box />
      <LinkButton
        onClick={() => {
          closeSheet();
          navTo.move(salesCommonParameters);
        }}
      >
        <Text inline={true}>Move in to a new property</Text>
        <IconNewWindow color="neutralLight" />
      </LinkButton>
      <LinkButton
        onClick={() => {
          closeSheet();
          navTo.disconnect();
        }}
      >
        <Text align="left">Disconnect a property</Text>
        <IconChevronRight color="neutralLight" />
      </LinkButton>
      {/*Empty box used to create dividers and spacing for links in stack */}
      <Box />
    </Stack>
  );
};
