import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  eventListener,
  TOPICS,
  ISubscribeSelectedProperty,
} from "@origin-digital/event-dispatcher";
import {
  fetchUserContextV3,
  type IProperty,
  type IUser,
  readPropertyFromStorage,
  writePropertyToStorage,
} from "./UserContextService";

export type UserContextType = {
  setProperty: (p: IProperty | null) => void;
  property: IProperty | null;
  user?: IUser;
};

export const UserContext = createContext<UserContextType>({
  setProperty: () => {},
  property: null,
  user: undefined,
});

export const useUserContext = () => useContext(UserContext);

//Instantiated outside the component, because we don't want to have it linked to react lifecycle
const subscribers: ((property: IProperty | null) => IProperty | null)[] = [];

const handleSubscribeProperty = (event: ISubscribeSelectedProperty) => {
  const subscriber = event.payload.subscriber;
  subscribers.push(subscriber);
  return () => subscribers.splice(subscribers.indexOf(subscriber), 1);
};

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<IUser>();
  const [property, setProperty] = useState<IProperty | null>(null);

  useEffect(() => {
    const loadUser = async () => {
      const fetchedUser = await fetchUserContextV3();
      setUser(fetchedUser);
      const loadedProperty = readPropertyFromStorage();
      setProperty(loadedProperty ?? null);
    };
    loadUser();
  }, []);

  useEffect(() => {
    subscribers.forEach((subscriber) => {
      subscriber(property);
    });
    writePropertyToStorage(property);
  }, [property]);

  useEffect(() => {
    //TODO-MB this may always return initial user. Not currently used, but needs to be validated if it works post logout
    const handleFetchContext = () => user;
    const unsubFetchUser = eventListener.addListener(
      TOPICS.FETCH_USER_CONTEXT,
      handleFetchContext
    );
    const unsubSubscribeProperty = eventListener.addListener(
      TOPICS.SUBSCRIBE_SELECTED_PROPERTY,
      handleSubscribeProperty
    );
    return () => {
      unsubFetchUser();
      unsubSubscribeProperty();
    };
    //TODO-MB check dependency array
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setProperty,
        property,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
