import { CustomerType } from "@origin-digital/platform-enums";
import { CommonParameters, IMcAppType, NavFlow, Tab } from "../mcApp.types";

const { accountNumber } = CommonParameters;

export const assistanceDebtAndOngoing: IMcAppType = {
  name: "assistanceDebtAndOngoing",
  path: "/my/assistance/debt-and-ongoing/hardship-assessment",
  parameters: {
    properties: { accountNumber },
  },
  description: "Assistance for payment plans for a given account number",
  navFlow: NavFlow.FOCUS,
  title: "Payment plan",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "assistanceDebtAndOngoing",
    customerType: CustomerType.kraken,
  },
};
