import {
  IImage,
  ICardCTA,
  ContentCardType,
  ICustomAttribute,
  ICustomEvent,
} from "@origin-digital/event-dispatcher";

import { logger } from "@origin-digital/reporting-client";
import { Card } from "@braze/web-sdk";
import { IBrazeContentCard } from "../consts/interfaces";
import { MAX_CONTENT_CARDS } from "../consts/consts";

// Sort descending order
// Missing or borked rank count as a zero
export const orderCards = (cards: ContentCardType[]) => {
  const sortedCards = cards;
  sortedCards.sort((a, b) => {
    const numA = a.rank ?? 0;
    const numB = b.rank ?? 0;
    return numB - numA;
  });

  return sortedCards;
};

// Filter on location only
export const filterCards = (
  arr: ContentCardType[],
  query: string,
  key: string
): ContentCardType[] => {
  if (arr?.length > 0) {
    return arr.filter((card) => {
      return card?.[key as keyof typeof card] === query;
    });
  } else {
    return [];
  }
};

// Handling the Control Group campaign cards and/or mis-configured cards
// ab-control-cards have:
// * isControl is true
// * title and description are both empty strings
// * extras are null
export const filterControlCards = (arr: IBrazeContentCard[]) => {
  return arr.filter((card) => {
    try {
      return (
        !card?.isControl ||
        !!(card?.extras && (card?.title || card?.description))
      );
    } catch {
      logger.debug(
        `[braze]: filterControlCards: issue with content card data ${card}`
      );
      return null;
    }
  });
};

export const getFilteredRankedCards = (
  cards: (ContentCardType | null)[],
  location: string | undefined,
  limit: number | undefined
): ContentCardType[] | [] => {
  if (!cards) {
    return [];
  }

  let filteredCards = cards;

  // filter out all the null results
  filteredCards = filteredCards.filter(
    (card): card is ContentCardType => card !== null
  );

  if (location) {
    // Remove all cards in the wrong location
    filteredCards = filterCards(
      filteredCards as ContentCardType[],
      location,
      "location"
    );
  }

  // Reorder
  filteredCards = orderCards(filteredCards as ContentCardType[]);

  if (limit) {
    // Limit if set
    filteredCards = filteredCards.slice(0, limit);
  }

  return filteredCards as ContentCardType[];
};

export const updateBrazeCardForTracking = (
  card: IBrazeContentCard
): IBrazeContentCard => {
  const values = JSON.parse(card.description);
  // Update the title, description, linkText and url fields for tracking the following:
  // * impression
  // * CTA clicks
  // * Dismiss clicks
  const { title, description, ctaContained, ctaOutlined, ctaLink } = values;
  card.title = title;
  card.description = description;
  card.linkText =
    ctaContained?.label || ctaOutlined?.label || ctaLink?.label || "";
  card.url =
    ctaContained?.action || ctaOutlined?.action || ctaLink?.action || "";
  return card;
};

const getMediaObject = (
  cloudinaryPath?: string,
  altText?: string
): IImage | undefined =>
  cloudinaryPath ? { cloudinaryPath, altText: altText ?? "" } : undefined;

const getCTAObject = (
  label?: string,
  action?: string,
  logClick?: () => void,
  logContentCardCustomEvent?: () => void,
  setCustomUserAttribute?: () => void,
  ariaLabel?: string
): ICardCTA | undefined =>
  label && action
    ? {
        label,
        action,
        logClick,
        logContentCardCustomEvent,
        setCustomUserAttribute,
        ariaLabel,
      }
    : undefined;

/**
 * @deprecated: using fields instead of JSON
 * can be removed once cards switch over
 */
const getContentCardFromBrazeCardsFields = (
  card: IBrazeContentCard,
  logClick: () => void
) => {
  const { extras, title, pinned } = card;
  const {
    iconCloud,
    iconAlt,
    imageCloud,
    imageAlt,
    imageMobileCloud,
    imageMobileAlt,
    hasPostcode,
    ctaOutlinedLabel,
    ctaOutlinedAction,
    ctaLinkLabel,
    ctaLinkAction,
    ...nonMappedExtras
  } = extras;

  return {
    ...nonMappedExtras,
    title,
    disableClose: pinned,
    icon: getMediaObject(iconCloud, iconAlt),
    image: getMediaObject(imageCloud, imageAlt),
    imageMobile: getMediaObject(imageMobileCloud, imageMobileAlt),
    hasPostcode: hasPostcode ? !!hasPostcode : undefined,
    body: card.description,
    ctaContained: getCTAObject(card.linkText, card.url, logClick),
    ctaOutlined: getCTAObject(ctaOutlinedLabel, ctaOutlinedAction, logClick),
    ctaLink: getCTAObject(ctaLinkLabel, ctaLinkAction, logClick),
    type: extras.type,
  };
};

// Translate Braze cards to Content Cards
// Need to pass through original Braze card for tracking
// Allowing for both JSON formatted content and older field values content

export const translateBrazeCardsToContentCards = (
  cards: IBrazeContentCard[],
  logCardClick: (card: IBrazeContentCard, forContentCards?: boolean) => void,
  logCardDismissal: (card: IBrazeContentCard) => void,
  logCardImpressions: (
    card: IBrazeContentCard[],
    forContentCards?: boolean
  ) => void,
  logCustomEvent: (customEvent: ICustomEvent) => void,
  setCustomAttribute: (customAttribute: ICustomAttribute) => void
): ContentCardType[] | [] => {
  // remove Control Group cards
  const filteredCards = filterControlCards(cards);
  if (!filteredCards.length) {
    return [];
  }

  const arr: (ContentCardType | undefined)[] = filteredCards.map((card) => {
    const logImpression = () => logCardImpressions([card], true);
    const logClick = () => logCardClick(card, true);
    const logClose = () => logCardDismissal(card);

    // Handle JSON formatted content
    try {
      const values = JSON.parse(card.description);

      updateBrazeCardForTracking(card);
      const {
        title,
        description,
        pinned,
        ctaContained,
        ctaOutlined,
        ctaLink,
        imageCloud,
        imageAlt,
        iconCloud,
        iconAlt,
        imageMobileCloud,
        ...rest
      } = values;

      return {
        ...rest,
        title,
        disableClose: !!pinned,
        icon: getMediaObject(iconCloud, iconAlt),
        image: getMediaObject(imageCloud, imageAlt),
        imageMobile: getMediaObject(imageMobileCloud, ""),
        hasPostcode: rest.hasPostcode ?? undefined,
        body: description,
        logClose,
        logImpression,
        ctaContained: getCTAObject(
          ctaContained?.label,
          ctaContained?.action,
          logClick,
          () => logCustomEvent(ctaContained?.customEvent),
          () => setCustomAttribute(ctaContained?.customAttribute),
          ctaContained?.ariaLabel
        ),
        ctaOutlined: getCTAObject(
          ctaOutlined?.label,
          ctaOutlined?.action,
          logClick,
          () => logCustomEvent(ctaOutlined?.customEvent),
          () => setCustomAttribute(ctaOutlined?.customAttribute),
          ctaOutlined?.ariaLabel
        ),
        ctaLink: getCTAObject(
          ctaLink?.label,
          ctaLink?.action,
          logClick,
          () => logCustomEvent(ctaLink?.customEvent),
          () => setCustomAttribute(ctaLink?.customAttribute),
          ctaLink?.ariaLabel
        ),
      } as ContentCardType;
    } catch (error) {
      // Handle old field version
      try {
        return {
          logClose,
          logImpression,
          ...getContentCardFromBrazeCardsFields(card, logClick),
        } as ContentCardType;
      } catch {
        logger.debug(
          `[braze]: translator issue with content card data ${card}`
        );
        return undefined;
      }
    }
  });
  // filter out undefined items:
  return arr.filter((item): item is ContentCardType => item !== undefined);
};

export const checkContentCardsLimit = (cards: Card[]) => {
  if (cards.length >= MAX_CONTENT_CARDS) {
    logger.warn(
      `[braze] User has hit the limit of ${MAX_CONTENT_CARDS} content cards in their feed`
    );
  }
};
