import React, { useEffect, useState } from "react";

import { auth0EnvConfigs } from "@origin-digital/origin-auth";
import {
  eventDispatcher,
  eventListener,
  Multicast,
  TOPICS,
} from "@origin-digital/event-dispatcher";
import { BannerView } from "./BannerView";
import {
  bannerState,
  createBannerState,
  CrisisBannerContent,
  getBannersInState,
  getCachedJwtBackends,
  hasMatchingBackend,
  isCrisisBannerPreview,
  loadContent,
  updateLocalStorage,
} from "./helpers";

export const CrisisBanner = () => {
  const [showBanner, setShowBanner] = useState(true);
  const [bannerContent, setBannerContent] = useState<
    CrisisBannerContent[] | undefined
  >(undefined);

  const onCloseButtonClick = () => {
    eventDispatcher.dispatch({
      topic: TOPICS.CRISIS_BANNER_CLOSE,
      payload: {},
      multicast: Multicast.ALL_TABS,
    });
    //Only close displayed banners, in (officially unsupported) case that there are open banners that aren't displayed
    const bannerStatus = createBannerState("closed", bannerContent);
    updateLocalStorage(bannerState, bannerStatus);
    setShowBanner(false);
  };

  useEffect(() => {
    return eventListener.addMulticastListener(
      TOPICS.CRISIS_BANNER_CLOSE,
      () => {
        setShowBanner(false);
      }
    );
  }, [setShowBanner]);

  useEffect(() => {
    const loadBanner = async () => {
      const rawContent = await loadContent();

      const isPreview = isCrisisBannerPreview();
      const openBanners = getBannersInState("open", rawContent);

      if (openBanners.length === 0 && !isPreview) return;

      let content = openBanners;

      // grab the cached JWT backends.  This saves a fair bit of time over fetchPlatformParameters() as the
      // auth0 SDK is not involved and does not have to initialise and make back end calls.
      // Additionally, the auth0 SDK seems to have issues in func tests.  If the user has already
      // authenticated then the JWT will be cached.  So, pretty simple even if not perfect (like the JWT
      // could have expired), but, this is not about securing anything, just checking what messages
      // to show.
      const jwtBackEnds = getCachedJwtBackends(
        auth0EnvConfigs[window.oetal.env.name]
      );
      const backEnds =
        jwtBackEnds && jwtBackEnds.length ? jwtBackEnds : ["UNAUTH"];

      // if not previewing, filter down banner list based on backends. If previewing, show everything
      if (!isPreview) {
        content = content
          .filter(
            (item) =>
              hasMatchingBackend(item.acf.crisisLocation, backEnds) && item.id
          )
          .slice(0, 2);
      }

      const showCrisisBanner = !!content?.length;

      if (showCrisisBanner) {
        setShowBanner(true);
        setBannerContent(content);
      }
    };
    try {
      loadBanner();
    } catch (error) {
      let message = "Unknown Error";
      if (error && !!error["message"]) message = error["message"];
      console.error("CrisisBanner Error:", { message });
    }
  }, []);

  return showBanner && bannerContent ? (
    <>
      {bannerContent.map((item, index) => {
        return (
          <BannerView
            key={index}
            index={index}
            bannerId={item.id}
            type={item.acf.crisisBannerType}
            heading={item.title.rendered}
            message={item.acf.crisisMessage}
            onCloseButtonClick={onCloseButtonClick}
            closeBtnDataId="cbCloseBtn"
          />
        );
      })}
    </>
  ) : null;
};
